<template>
  <div class="flex flex-col my-10">
    <!-- Header : Title And Documention -->
    <div class="mx-3 mb-2 flex flex-col md:flex-row justify-between">
      <!--grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-5-->
      <p class="font-medium">
        {{ $t("integration.menu.api") }}
      </p>
      <div class="flex flex-inline space-x-2">
        <app-button
          :primary="false"
          width="w-auto"
          :showf70Icon="false"
          @click="openApiDoc"
        >
          <template v-slot:icon>
            <app-icon-outline
              name="BookOpenIcon"
              class="h-4 w-4 text-black mr-2"
            />
          </template>
          {{ $t("integration.api.api_docs") }}
        </app-button>
      </div>
    </div>

    <hr />

    <!-- API KEY -->
    <div class="flex flex-col mx-8 mt-6">
      <app-form-select-master-business-list-business
        :includeMasterBusiness="true"
        v-if="hasRoleMasterBusiness"
        v-model="selectBusiness"
        class="w-full"
        :loading="loading"
      />
      <div
        class="
          flex flex-col
          md:flex-row md:space-y-0
          space-y-5 space-x-4
          items-end
          mt-6
        "
      >
        <!-- Form API key  -->
        <div class="flex-auto relative w-full">
          <app-form-input
            type="text"
            class="w-full"
            :disabled="true"
            :labelText="$t('integration.api.key')"
            v-model="integration.api_key"
          />
          <button
            class="absolute top-9 right-1"
            :disabled="integration.api_key == null"
            @click="copyApiKey(integration.api_key)"
          >
            <app-icon name="ClipboardCopyIcon" class="h-6 w-6 text-gray-400" />
          </button>
        </div>

        <!-- Reset API key  -->
        <app-button
          class="flex-none"
          width="w-auto"
          :showf70Icon="false"
          :disabled="integration.api_key == null"
          :loading="loading"
          @click="showResetApiDialog()"
        >
          {{ $t("integration.api.reset_button") }}
        </app-button>
      </div>

      <!-- Footer : Note API Document  -->
      <div class="flex flex-wrap mt-9 text-sm mb-2">
        <span>
          {{ $t("integration.api.notes_1") }}
        </span>
        <a href="#" class="mx-1" @click="openApiDoc">
          {{ $t("integration.api.api_docs") }}
        </a>
        <span>
          {{ $t("integration.api.notes_2") }}
        </span>
      </div>
    </div>

    <!-- Modal Confirm Reset -->
    <app-modal :show="showModalConfirm" @close="showModalConfirm = false">
      <template v-slot:title>{{
        $t("integration.api.reset_prompt_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("integration.api.reset_prompt") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="resetApi()"
          @click="showModalConfirm = false"
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="
            sm:ml-3 sm:w-auto
            bg-approve
            hover:bg-success
            text-white
            outline-none
          "
        >
          {{ $t("general.proceed") }}
        </app-button>
      </template>
    </app-modal>
  </div>
</template>
<script>
import ROLE from "@/utils/const/role";

export default {
  data() {
    return {
      ROLE: ROLE,
      showModalConfirm: false,
    };
  },

  computed: {
    integration() {
      return this.$store.getters["integrationStore/integration"];
    },

    loading() {
      return this.$store.getters["integrationStore/loading"];
    },

    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },

    selectBusiness() {
      return this.$store.getters["masterBusinessStore/selectBusiness"];
    },

    hasRoleMasterBusiness() {
      return this.$store.getters["authStore/hasRoleMasterBusiness"]();
    },
  },

  watch: {
    selectBusiness(business) {
      if (business.id) {
        this.$store.dispatch(
          "integrationStore/retrieveIntegration",
          business.id
        );
      }
    },
  },

  methods: {
    copyApiKey(key) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(key);

      this.$notify(
        {
          group: "success",
          title: this.$t("integration.api.copy"),
        },
        5000
      );
    },

    showResetApiDialog() {
      this.showModalConfirm = true;
    },

    resetApi() {
      this.$store.dispatch(
        "integrationStore/resetApiKey",
        this.selectBusiness?.id
      );
    },

    openApiDoc() {
      window.open(this.$store.$backendURL + "/docs", "_blank");
    },
  },
};
</script>